import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Notification {
  formName: string;
  message: string;
  read: boolean;
}

interface FacultyDetails {
  id: number;
  name: string;
  email: string;
  department: string;
}

interface NotificationBellProps {
  notifications: Notification[];
  markAsRead: (index: number) => void;
  markAllAsRead: () => void;
}

const NotificationBell: React.FC<NotificationBellProps> = ({
  notifications,
  markAsRead,
  markAllAsRead,
}) => {
  const [localNotifications, setLocalNotifications] = useState(notifications);
  const [emailText, setEmailText] = useState<string | null>(null);

  useEffect(() => {
    setLocalNotifications(notifications);
  }, [notifications]);

  const handleMarkAsRead = async (index: number) => {
    try {
      markAsRead(index);
      const notification = localNotifications[index];
      const updatedNotifications = [...localNotifications];
      updatedNotifications[index].read = true;
      setLocalNotifications(updatedNotifications);

      // Example logic to fetch email text after marking as read
      setEmailText(notification.message);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className="relative">
      <div className="absolute right-0 mt-2 w-[30rem] bg-white border border-gray-900 shadow-lg rounded-lg z-50">
        <div className="p-2 border-b border-gray-600 flex justify-between items-center">
          <span className="font-semibold text-black text-sm">Notifications</span>
          <button
            onClick={markAllAsRead}
            className="text-blue-500 text-xs hover:underline"
          >
            Mark All As Read
          </button>
        </div>
        <div className="overflow-y-auto max-h-[28rem]">
          {localNotifications.length > 0 ? (
            localNotifications.map((notification, index) => (
              <div
                key={index}
                className={`p-2 border-b border-gray-600 flex flex-col justify-start items-start ${
                  !notification.read ? 'bg-gray-300' : ''
                }`}
              >
                <div className="font-bold text-sm text-black mb-1">
                  {notification.formName}
                </div>
                <div className="text-xs text-gray-600 mb-2">
                  {notification.message}
                </div>
                {!notification.read && (
                  <button
                    onClick={() => handleMarkAsRead(index)}
                    className="text-blue-500 text-xs hover:underline mt-1 ml-auto"
                  >
                    Mark as Read
                  </button>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-center text-sm text-gray-600">
              No new notifications
            </div>
          )}
        </div>
        {emailText && (
          <div className="p-2 mt-2 bg-gray-100 border border-gray-300 rounded">
            <span className="text-sm font-semibold text-black">
              Email Notification:
            </span>
            <p className="text-xs text-gray-700">{emailText}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationBell;
