import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const Successfully: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Set a timer to show the animation after a short delay
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // Update window dimensions when resized
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex justify-center items-center text-center h-screen bg-white overflow-hidden font-sans">
      {isVisible && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
        />
      )}
      <div
        className={`transform transition-all duration-1500 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
          }`}
      >
        <h1 className="text-6xl md:text-7xl font-bold text-green-700 font-dancing-script mb-6">
          🎉 Thank You 🎉
        </h1>
        <div className="flex justify-center items-center w-44 h-44 mx-auto bg-green-700 rounded-full shadow-lg mb-6">
          <span style={{ color: 'white', fontSize: '4rem', fontWeight: 'bold' }}>&#10003; </span>

        </div>
        <p className="text-lg md:text-2xl text-gray-700">
          Congratulations! You’ve successfully registered.
        </p>
      </div>
    </div>
  );
};

export default Successfully;
