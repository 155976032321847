import React, { useEffect, useState } from 'react';
import '../styles/Flowchart.css';
import { useNavigate } from 'react-router-dom';
import saikiranPDF from '../img/Prithi.pdf';
import StudentFeedback from './StudentFeedback';
import axios from 'axios';
import { fetchUserProfile, updateUserProfile, UserProfile } from '../services/userService';

const Flowchart: React.FC = () => {
  const [assessmentClicked, setAssessmentClicked] = useState(false);
  const [feedbackClicked, setFeedbackClicked] = useState(false);
  const [paraColor, setParaColor] = useState('#2e6ca6'); // Initial color for paragraphs
  const [paraColorIndustry, setParaColorIndustry] = useState('#2e6ca6'); // Initial color for Industry Mentor paragraph
  const [reportButtonColor, setReportButtonColor] = useState('#2e6ca6'); // Initial color for "Generate Report" button
  const [startTransition, setStartTransition] = useState(false); // To track when both buttons are clicked
  const [downloadMessage, setDownloadMessage] = useState(''); // Message below Generate Report
  const [formData, setFormData] = useState({ userId: '' }); // Initialize form data
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
   const [feedbackGiven, setFeedbackGiven] = useState(true);
  const [Facultyfeedback, setFacultyfeedback] = useState(true);
  const [Industryfeedback, setIndustryfeedback] = useState(true);
  const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setFormData((prevFormData) => ({
            ...prevFormData,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);
  useEffect(() => {
  const fetchProfile = async () => {
    try {
      const user = await fetchUserProfile();
      if (user) {
        setUserProfile(user);
        console.log('feedbackGiven:', user.feedbackGiven);
        console.log('Facultyfeedback:', user.Facultyfeedback);
        console.log('Industryfeedback:', user.Industryfeedback);
      } else {
        setError('Error fetching user profile. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      setError('Error fetching user profile. Please try again.');
      setLoading(false);
      console.error('Error fetching user profile:', error);
    }
  };

  fetchProfile();
}, []);
 const downloadReport = async () => {
    try {
      const response = await fetch(`/api/reports/one/${formData.userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to download the report');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'CombinedReport.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the report:', error);
    }
  };



  const handleAssessmentClick = () => {
    setTimeout(() => {
      setAssessmentClicked(true); // Show message and change assessment button color to green
    }, 2000); // Delay the action by 2 seconds
  };

  const handleFeedbackClick = () => {
    setFeedbackClicked(true); // Show message and change feedback button color to green
  };



  useEffect(() => {
    if (assessmentClicked) {
      // Both buttons clicked, start paragraph and report button transitions
      setStartTransition(true);
    }
  }, [assessmentClicked]);

  useEffect(() => {
    if (startTransition) {
      // Change Faculty Feedback paragraph color to green after 2 seconds
      const facultyTimer = setTimeout(() => {
        setParaColor('green');
      }, 2000);

      // Change Industry Mentor Feedback paragraph color to green after 4 seconds
      const industryTimer = setTimeout(() => {
        setParaColorIndustry('green');
      }, 4000);

      // Display message below the "Generate Report" button after 6 seconds
      const downloadTimer = setTimeout(() => {
        setReportButtonColor('green'); // Make the button green
        setDownloadMessage('You can now Download the report');
      }, 6000);

      // Clean up timers
      return () => {
        clearTimeout(facultyTimer);
        clearTimeout(industryTimer);
        clearTimeout(downloadTimer);
      };
    }
  }, [startTransition]);

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const handleUploadClick = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setFilePreview(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFilePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    closePopup();
  };

  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);

  return (








      <div className="flex flex-col items-center justify-center space-y-8 p-8 border border-gray-300 rounded-lg">
        {/* Top Button */}
        <div className="w-full flex justify-center">
          <button
            className="w-full max-w-xs px-4 py-2 bg-blue-500 text-white rounded-lg"
            onClick={() => setIsFeedbackPopupVisible(true)} // Trigger feedback popup
          >
            Student Feedback
          </button>

          {isFeedbackPopupVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
                {/* Close Button */}
                <button
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-4xl"
                  onClick={() => setIsFeedbackPopupVisible(false)}
                >
                  &times;
                </button>
                {/* StudentFeedback Component */}
                <StudentFeedback />
              </div>
            </div>
          )}
        </div>

        {/* Middle Section: Arrow and Paragraph Side by Side */}
        <div className="flex justify-center items-center space-x-4 w-full">
          {/* Arrow aligned to the right */}
          <div className="w-1/2 flex justify-end ">
          {userProfile?.feedbackGiven ?  (
            <span
              className="text-6xl text-black"
              style={{ lineHeight: '3rem' }}
            >
              &#8675; {/* Down arrow in black */}
            </span>
          ) : (
            // Arrow when feedback is submitted
            <span
              className="text-6xl text-green-500"
              style={{ lineHeight: '3rem' }}
            >
              &#8595; {/* Down arrow in green */}
            </span>
          )}


          </div>

          {/* Content aligned to the left */}
          <div className="w-1/2 flex justify-start pl-6">
            <div>
           {userProfile?.feedbackGiven ? (
  <p className="text-green-500">Feedback Submitted</p>
) : (
  <p className="text-red-500">Give feedback to Industry & Internship Program</p>
)}

            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <p className="w-full max-w-xs px-4 py-2 rounded-lg text-black text-center" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            Industry Mentor Feedback
          </p>
        </div>

        {/* Next Arrow and Paragraph */}
        <div className="flex justify-center items-center space-x-4 w-full">
          {/* Arrow aligned to the right */}
          <div className="w-1/2 flex justify-end ">
          {userProfile?.Facultyfeedback ? (
           
            <span
              className="text-6xl text-black"
              style={{ lineHeight: '3rem' }}
            >
              &#8675; {/* Down arrow in black */}
            </span>
          ) : (
            // Arrow when feedback is submitted
            <span
              className="text-6xl text-green-500"
              style={{ lineHeight: '3rem' }}
            >
              &#8595; {/* Down arrow in green */}
            </span>
          )}


          </div>

          {/* Content aligned to the left */}
          <div className="w-1/2 flex justify-start pl-6">
            <div>
              {userProfile?.Facultyfeedback ? (
                <p className="text-red-500">Await feedback to Industry & Internship Program</p>
              ) : (
                <p className="text-green-500">Feedback Submitted</p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <p className="w-full max-w-xs px-4 py-2 rounded-lg text-black text-center" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            Industry Mentor Feedback
          </p>
        </div>

        {/* Final Arrow and Paragraph */}
        <div className="flex justify-center items-center space-x-4 w-full">
          {/* Arrow aligned to the right */}
          <div className="w-1/2 flex justify-end ">
          {userProfile?.Industryfeedback ? (
            <span
              className="text-6xl text-black"
              style={{ lineHeight: '3rem' }}
            >
              &#8675; {/* Down arrow in black */}
            </span>
          ) : (
            // Arrow when feedback is submitted
            <span
              className="text-6xl text-green-500"
              style={{ lineHeight: '3rem' }}
            >
              &#8595; {/* Down arrow in green */}
            </span>
          )}





          </div>

          {/* Content aligned to the left */}
          <div className="w-1/2 flex justify-start pl-6">
            <div>
              {userProfile?.Industryfeedback ? (
                <p className="text-red-500">Give feedback to Industry & Internship Program</p>
              ) : (
                <p className="text-green-500">Feedback Submitted</p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <button className="w-full max-w-xs px-4 py-2 bg-green-500 text-white rounded-lg" onClick={downloadReport}>
            Download Report
          </button>
        </div>
      </div>

  );
};





export default Flowchart;
