import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import Chatbot from '../components/Chatbot';
import { useNavigate } from 'react-router-dom';
import logoutImage from '../img/log-out_10117545.png';
import info from '../img/information-point_11606639 (1).png';
import { AiOutlineClose } from 'react-icons/ai';
import { CiLogout } from 'react-icons/ci'; // Adjust the path based on your icon library
import { FaUser, FaQuestionCircle } from 'react-icons/fa';
import FacultyCalendarComponent from '../components/facultyLogCal';
import FacultyAttendanceComponent from '../components/facultyAttendance';

import { FaBell, FaEnvelope } from 'react-icons/fa';
import { TbLogout2 } from "react-icons/tb";
import FeedbackFormFaculty from '../components/FeedbackFormFaculty';
import StudentCalendarComponent from '../components/studentAttendanceCalendar';
import NotificationBell from '../components/NotificationBell';
import SignatureBackgroundRemover from '../components/signUpload';


interface Notification {
  formName: string;
  message: string;
  read: boolean;
}

interface BranchDetails {
  BranchID: number;
  BranchName: string;
  degreeId: number;
}
interface User {
  id: number;
  IIMSTC_ID: string;
  name: string;
  dob: Date;
  address: string;
  collegeId: number;
  university: string;
  usn: string;
  email: string;
  gender: string;
  semester: string;
  phoneNumber: string;
  passportPhoto: string;
  aadharNo: string;
  aadharProof: string;
  degreeId: number;
  degreeStatusId: number | null;
  branchId: number;
  InternshipApproved: boolean | null;
  interviewScheduled: boolean;
  interviewReject: boolean;
  branchDetails: BranchDetails;
  collegeDetails: CollegeDetails;
  Facultyfeedback: boolean;
}
interface CollegeDetails {
  id: number;
  name: string;
  code: string;
  Active_Status: string;
}
interface Attendance {
  date: string;
  isPresent: boolean;
  userId: number;
  userName: string;
}

interface DailyLog {
  id: number;
  day: string;
  date: string;
  arrivalTime: string;
  departureTime: string;
  remarks?: string;
  department: string;
  finishedProduct: string;
  hodName: string;
  hodEmail: string;
  mainPoints: string;
}

interface User {
  id: number;
  name: string;
  email: string;
}
interface FacultyDetails {
  id: number;
  name: string;
  email: string;
  department: string;
  company: string;
  designation: string;
  address: string;
  phoneNumber: string;
  // Add other fields as necessary
}
const FacultyDashboard: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [dailyLogs, setDailyLogs] = useState<DailyLog[]>([]);
  const [attendance, setAttendance] = useState<DailyLog[]>([]);
  const [selectedLog, setSelectedLog] = useState<DailyLog | null>(null);
  const [attendanceData, setAttendanceData] = useState<{ [date: string]: Attendance[] }>({});
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
const [showFeedbackForms, setShowFeedbackForms] = useState<{ [userId: number]: boolean }>({});
  const [facultyDetails, setFacultyDetails] = useState<FacultyDetails | null>(null);
  const [viewOnlyFeedback, setViewOnlyFeedback] = useState<{ [userId: number]: boolean }>({});
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  
  const [unreadCount, setUnreadCount] = useState(0);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    // Perform any necessary cleanup, such as clearing tokens
    navigate('/reg'); // Navigate to /reg
  };
  const [activeSection, setActiveSection] = useState<'none' | 'dashboard' |'feedback' |'signature'| 'attendance' | 'account'>('account');
  const [attendanceDate, setAttendanceDate] = useState<string>('');
useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const facultyId = localStorage.getItem('facultyId');
        if (facultyId) {
          const response = await axios.get(`/api/notifications/${facultyId}`);
          setNotifications(response.data.notifications);
          const unreadCount = response.data.notifications.filter(
            (notification: Notification) => !notification.read
          ).length;
          setUnreadCount(unreadCount);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const markAsRead = async (index: number) => {
    try {
      const updatedNotifications = [...notifications];
      updatedNotifications[index].read = true;
      setNotifications(updatedNotifications);

      const updatedUnreadCount = updatedNotifications.filter(
        (notification) => !notification.read
      ).length;
      setUnreadCount(updatedUnreadCount);

      // Update the notification read status on the backend
      const facultyId = localStorage.getItem('facultyId');
      if (facultyId) {
        await axios.post(`/api/notifications/mark-as-read`, {
          facultyId,
          index,
        });
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        read: true,
      }));
      setNotifications(updatedNotifications);
      setUnreadCount(0);

      // Update all notifications as read on the backend
      const facultyId = localStorage.getItem('facultyId');
      if (facultyId) {
        await axios.post(`/api/notifications/mark-all-as-read`, {
          facultyId,
        });
      }
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

 useEffect(() => {
    const fetchFacultyIdAndUsers = async () => {
      const adminId = localStorage.getItem('adminId');

      if (!adminId) {
        setError('Admin ID not found in local storage.');
        return;
      }

      try {
        const facultyResponse = await axios.get(`/api/faculties/${adminId}`);

        if (facultyResponse.data && facultyResponse.data.id) {
          setFacultyDetails(facultyResponse.data); // Set faculty details here
          const facultyId = facultyResponse.data.id;

          try {
            const usersResponse = await axios.get(`/api/users/faculty/${facultyId}`);

            if (usersResponse.data && Array.isArray(usersResponse.data.users)) {
              setUsers(usersResponse.data.users);
            } else {
              console.error('API response does not contain a valid array of users:', usersResponse.data);
              setError('Invalid API response');
            }
          } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users');
          }
        } else {
          console.error('Faculty details not found:', facultyResponse.data);
          setError('Faculty details not found');
        }
      } catch (error) {
        console.error('Error fetching faculty and users:', error);
        setError('Failed to fetch faculty or users');
      }
    };

    fetchFacultyIdAndUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const fetchDailyLogs = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`/api/daily-logs/${selectedUser.id}`);
          if (response.data.success) {
            setDailyLogs(response.data.dailyLogs);
          } else {
            console.error('API response does not contain valid daily logs:', response.data);
          }
        } catch (error) {
          console.error('Error fetching daily logs:', error);
          setError('Failed to fetch daily logs');
        } finally {
          setLoading(false);
        }
      };

      fetchDailyLogs();
    }
  }, [selectedUser]);
useEffect(() => {
    if (selectedUser) {
     const fetchAttendance = async () => {
    setLoading(true);
    try {
        const response = await axios.get(`/api/attendance/${selectedUser.id}`);
        if (response.data.success) {
            setAttendance(response.data.attendanceRecords); // Adjust this line to match your API response
        } else {
            console.error('API response does not contain valid daily logs:', response.data);
        }
    } catch (error) {
        console.error('Error fetching daily logs:', error);
        setError('Failed to fetch daily logs');
    } finally {
        setLoading(false);
    }
};

      fetchAttendance();
    }
  }, [selectedUser]);
  const handleSaveAttendance = async () => {
    if (!attendanceDate) {
      alert('Please enter the attendance date');
      return;
    }

    const attendanceRecords = users.map((user) => {
      const inputElement = document.querySelector(`input[name="attendance-${user.id}"]:checked`) as HTMLInputElement;
      const isPresent = inputElement?.value === 'present';
      return { id: user.id, isPresent };
    });

    try {
      const response = await axios.post('/api/attendance/save-all', {
        attendanceDate,
        attendanceRecords,
      });

      if (response.data.success) {
        alert('Attendance saved successfully for all users!');
      } else {
        alert('Failed to save attendance for all users');
      }
    } catch (error) {
      console.error('Error saving attendance for all users:', error);
      setError('Failed to save attendance for all users');
    }
  };

  const handleViewAttendance = async () => {
    try {
      const response = await axios.get('/api/attendance/list');
      if (response.data.success) {
        const attendanceList = response.data.attendanceList.reduce((acc: any, record: any) => {
          const date = new Date(record.date).toLocaleDateString();
          if (!acc[date]) acc[date] = [];
          acc[date].push({
            ...record,
            userName: record.user.name,
          });
          return acc;
        }, {});
        setAttendanceData(attendanceList);
      } else {
        alert('Failed to fetch attendance');
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setError('Failed to fetch attendance');
    }
  };

  const handleDateClick = (date: string) => {
    setSelectedDate(date === selectedDate ? null : date);
  };


const exportLogs = async (userId: string | number) => {
  try {
    const response = await fetch(`/api/export/${userId}`);
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DailyLogs_${userId}.xlsx`);
      document.body.appendChild(link);
      link.click();

      // Use remove() method to delete the link element
      link.remove();
    } else {
      const errorData = await response.json();
      setError(errorData.message || 'Failed to export logs.');
    }
  } catch (err) {
    setError('Failed to export logs.');
  }
};

  const handleShowFeedbackForm = (userId: number, viewOnly: boolean = false) => {
    setShowFeedbackForms((prevState) => ({
      ...prevState,
      [userId]: true,
    }));
    setViewOnlyFeedback((prevState) => ({
      ...prevState,
      [userId]: viewOnly,
    }));
  };

  const handleHideFeedbackForm = (userId: number) => {
    setShowFeedbackForms((prevState) => ({
      ...prevState,
      [userId]: false,
    }));
    setViewOnlyFeedback((prevState) => ({
      ...prevState,
      [userId]: false,
    }));
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const openPopup = () => setIsPopupVisible(true);
  const closePopup = () => setIsPopupVisible(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreview(null);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile) {
      // Implement file upload logic here
      alert('File uploaded successfully!');
      closePopup();
    } else {
      alert('Please select a file.');
    }
  };


const [notifications, setNotifications] = useState<Notification[]>([
    { formName: 'Form 1', message: 'New submission received', read: false },
    { formName: 'Form 2', message: 'Review pending', read: false },

  ]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

 














 const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const goBack = () => {
    navigate('account'); // Use navigate instead of history.push
  };

  return (
    <>
      {/* Navigation Bar */}
      <nav className="bg-blue-600 text-white p-4 flex justify-end items-center fixed top-0 left-0 w-full shadow-lg z-50">
        {/* Logo */}


        {/* Navigation Icons */}
        <div className="flex space-x-6 ml-auto pl-2"> {/* ml-auto aligns icons to the right */}
          {/* Notification Icon with Tooltip */}
          <div className="relative">
            {/* Bell icon with notification count */}
            <div onClick={() => setIsDropdownVisible(!isDropdownVisible)} className="relative cursor-pointer">
              <FaBell className="text-xl hover:text-gray-300" />
              {unreadCount > 0 && (
                <span className="absolute top-[-9px] right-[-8px] bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  {unreadCount}
                </span>
              )}
            </div>

            {/* Dropdown with notifications */}
            {isDropdownVisible && (
              <NotificationBell
                notifications={notifications}
                markAsRead={markAsRead}
                markAllAsRead={markAllAsRead}
              />
            )}
          </div>


          {/* Help Icon with Tooltip */}
          <div className="relative group">
            <FaQuestionCircle className="text-xl cursor-pointer hover:text-gray-300" />
            <div className="absolute right-full mr-2 top-6 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Help
            </div>
          </div>

          {/* Message Icon with Tooltip */}
          <div className="relative group">
            <FaEnvelope className="text-xl cursor-pointer hover:text-gray-300" />
            <div className="absolute right-full mr-2 top-6 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Message
            </div>
          </div>

          {/* Logout Icon with Tooltip */}
          <div className="relative group">
            <TbLogout2
              onClick={handleLogout}
              className="text-xl cursor-pointer hover:text-gray-300 transform hover:scale-105 transition duration-200"
            />
            <div className="absolute right-full mr-2 top-8 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Logout
            </div>

          </div>
        </div>
      </nav>
      <div className="pt-16"> {/* Add padding to ensure content doesn't hide under the navbar */}
        <div className={`relative flex ${isSidebarMinimized ? 'ml-16' : 'ml-64'} transition-all duration-300`}>

          <div
            className="bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-[94%] md:h-[90%] z-10 left-0 content-sidebar w-[6rem] sm:w-[19rem]"
            style={{
              background: "linear-gradient(45deg, rgb(7, 21, 35), rgb(0, 25, 50))",
              borderRadius: "20px"
            }}
          >
        {!isSidebarMinimized && (
          <>
            {/* Logo Image */}
            <div className="flex flex-col items-center">

              <img
                src="https://iimstc.com/wp-content/uploads/2021/10/log.png"
                alt="Logo"
                className="h-14 w-26 mt-6 ml-2 mr-2"
              />

              {/* Faculty Name Container */}
              <div className="text-center mb-6">
                <img
                  src="https://cdn3.iconfinder.com/data/icons/education-248/128/4-512.png"
                  alt="Logo"
                  className="w-[120px] h-[120px] mb-6 mt-6 ml-4 mr-2"
                />
               <p className="text-xl mt-2 font-bold">Hi, {facultyDetails?.name || 'N/A'}</p>
                <p className="text-lg mt-1 italic text-white"> {facultyDetails?.company || 'N/A'}</p>
              </div>
            </div>





            {/* Navigation Button Container */}
            <div className="flex flex-col space-y-4 ml-2">
              <button
                onClick={() => setActiveSection('account')}
                className={`mb-4 mr-4 p-2 rounded-md flex items-center ${activeSection === 'account' ? 'bg-white text-custom-teal' : ''}`}
                style={{ marginLeft: '15px' }}
              >
                <FaUser size={20} className="mr-2" /> {/* Account Icon */}
                <span className="font-bold">Account</span>
              </button>

            </div>

            
          </>
        )}
      </div>




      <div className={`bg-white transition-all duration-300 fixed top-20 ${isSidebarMinimized ? 'left-20' : 'left-60'} w-[calc(100%-80px)] h-[calc(100vh-56px)] overflow-y-auto`}>
        {activeSection === 'account' && (
          <div className="mt-6 flex justify-center">
            <div className="w-full">
              <div className="flex justify-center items-center">
                <div className="flex gap-5 w-[80%]">
                  <div
                    className="w-52 bg-white p-5 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:-translate-y-2"
                    style={{ backgroundColor: '#204598', cursor: 'pointer' }} // Added cursor: pointer for visual feedback
                    onClick={() => setActiveSection('dashboard')} // Move the onClick event to the entire div
                  >
                    <h2 className="mb-5 text-white font-semibold">Daily Logs</h2>
                    <svg className=" ml-14 w-[45px] h-[55px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" id="Logs">
                      <path fill="#e6e7e8" d="M112.545,40.831a1,1,0,0,0-.336-.746L84.751,12.626a1,1,0,0,0-.707-.292H34.879a5.006,5.006,0,0,0-5,5v60.4H24.455a5.006,5.006,0,0,0-5,5v22.778a5.006,5.006,0,0,0,5,5h5.424v4.154a5.006,5.006,0,0,0,5,5h72.666c2.9,0,5-2.366,5-5.625Z" className="colore6e7e8 svgShape"></path>
                      <path fill="#87ddfd" d="M107.543,36.833l-27.5-27.5H30.879a4,4,0,0,0-4,4v61.4H81.372a4,4,0,0,1,4,4v22.778a4,4,0,0,1-4,4H26.879v5.154a4,4,0,0,0,4,4h72.666a4,4,0,0,0,4-4V36.831Z" className="color6dc9f7 svgShape"></path>
                      <path fill="#00aeef" d="M80.046,9.333l0,0v23.5a4,4,0,0,0,4,4h23.5Z" className="color0089ef svgShape"></path>
                      <path fill="#ffffff" d="M80.044,9.335H30.879a4,4,0,0,0-4,4v3a4,4,0,0,1,4-4H80.044Z" className="colorffffff svgShape"></path>
                      <rect width="68.917" height="30.778" x="16.455" y="74.735" fill="#d7f4ff" rx="4" ry="4" className="colorffd7e5 svgShape"></rect>
                      <path fill="#2c5260" d="M38.138 27.83H71.286a1 1 0 0 0 0-2H38.138a1 1 0 1 0 0 2zM38.138 37.83H71.286a1 1 0 0 0 0-2H38.138a1 1 0 1 0 0 2zM96.286 45.83H38.138a1 1 0 1 0 0 2H96.286a1 1 0 0 0 0-2zM96.286 55.83H38.138a1 1 0 1 0 0 2H96.286a1 1 0 0 0 0-2zM96.286 65.83H38.138a1 1 0 1 0 0 2H96.286a1 1 0 0 0 0-2zM107.545 68.333a1 1 0 0 0-1 1V80a1 1 0 0 0 2 0V69.333A1 1 0 0 0 107.545 68.333z" className="color3a2c60 svgShape"></path>
                      <path fill="#2c5260" d="M108.209,36.085,80.751,8.626a1,1,0,0,0-.707-.293H30.879a5.006,5.006,0,0,0-5,5v60.4H20.455a5.006,5.006,0,0,0-5,5v22.778a5.006,5.006,0,0,0,5,5h5.424v4.153a5.006,5.006,0,0,0,5,5h72.666a5.006,5.006,0,0,0,5-5V84.375a1,1,0,0,0-2,0v26.291a3,3,0,0,1-3,3H30.879a3,3,0,0,1-3-3v-4.153H81.372a5.006,5.006,0,0,0,5-5V78.735a5.006,5.006,0,0,0-5-5H27.879v-60.4a3,3,0,0,1,3-3H79.044v22.5a5.006,5.006,0,0,0,5,5h22.5V65a1,1,0,0,0,2,0V36.831A1,1,0,0,0,108.209,36.085ZM84.372,78.735v22.778a3,3,0,0,1-3,3H20.455a3,3,0,0,1-3-3V78.735a3,3,0,0,1,3-3H81.372A3,3,0,0,1,84.372,78.735Zm-3.328-45.9V11.748l24.085,24.085H84.044A3,3,0,0,1,81.044,32.833Z" className="color3a2c60 svgShape"></path>
                      <path fill="#2c5260" d="M29.674 98.584H37.81q1.75 0 1.752-1.512 0-1.534-1.752-1.535H31.666V83.393a1.817 1.817 0 0 0-.5-1.345 2.109 2.109 0 0 0-2.736 0 1.817 1.817 0 0 0-.5 1.345V96.832a1.589 1.589 0 0 0 1.752 1.752zM43.87 97.732a9.515 9.515 0 0 0 8.76 0A7.213 7.213 0 0 0 55.5 94.708a10.98 10.98 0 0 0 0-9.191A7.139 7.139 0 0 0 52.63 82.5a9.612 9.612 0 0 0-8.76 0A7.139 7.139 0 0 0 41 85.517a10.98 10.98 0 0 0 0 9.191A7.213 7.213 0 0 0 43.87 97.732zm1.056-11.9a4.494 4.494 0 0 1 6.66 0 8.233 8.233 0 0 1-.012 8.58 4.446 4.446 0 0 1-6.637 0 8.307 8.307 0 0 1-.011-8.58zM62.962 97.756A9.784 9.784 0 0 0 67.617 98.8a16.716 16.716 0 0 0 2.292-.168 13.679 13.679 0 0 0 2.149-.456 3.23 3.23 0 0 0 1.152-.528 1.652 1.652 0 0 0 .54-.839 5.566 5.566 0 0 0 .155-1.489V90.641a1.333 1.333 0 0 0-.395-1.02 1.5 1.5 0 0 0-1.069-.372h-4.1a1.591 1.591 0 0 0-1.08.335 1.392 1.392 0 0 0 0 1.92 1.586 1.586 0 0 0 1.08.336h2.208v3.721a10.848 10.848 0 0 1-2.761.359 4.859 4.859 0 0 1-3.84-1.44 6.446 6.446 0 0 1-1.271-4.368q0-5.688 4.9-5.688a6.183 6.183 0 0 1 1.849.264 8.409 8.409 0 0 1 1.848.889 4.036 4.036 0 0 0 .612.312 1.569 1.569 0 0 0 .563.095 1.023 1.023 0 0 0 .828-.444 1.674 1.674 0 0 0 .348-1.067 1.622 1.622 0 0 0-.143-.721 1.828 1.828 0 0 0-.5-.576 8.622 8.622 0 0 0-5.521-1.728A9.368 9.368 0 0 0 62.914 82.5a7.221 7.221 0 0 0-2.988 3.013 10.691 10.691 0 0 0 .011 9.239A7.181 7.181 0 0 0 62.962 97.756z" className="color3a2c60 svgShape"></path>
                    </svg>
                  </div>
                  <div
                    className="w-52 bg-white p-5 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:-translate-y-2"
                    style={{ backgroundColor: '#204598', cursor: 'pointer' }} // Added cursor: pointer for visual feedback
                    onClick={() => setActiveSection('attendance')} // Move the onClick event to the entire div
                  >
                    <h2 className="mb-5 text-white font-semibold">Attendance</h2>

                    <svg className=" ml-14 w-[65px] h-[55px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" id="Attendance">
                      <path fill="#5ed1fc" d="M118.375 18H117v3a13 13 0 0 1-26 0v-3.027l-54-.063V21a13 13 0 0 1-26 0v-3.12l-3.75-.005s-5 .125-5 5v15.25h123.5V21.75S125 18 118.375 18Z" className="colorfc785e svgShape"></path>
                      <path fill="#d7f0f9" d="M37 18h54v4H37z" opacity=".3" className="colorf9dbd7 svgShape"></path>
                      <path fill="#80ddff" d="M125.25 38.25v32.12A34.984 34.984 0 0 0 62 91a34.34 34.34 0 0 0 .71 7c.13.67.29 1.34.46 2 .09.33.19.67.29 1a2.322 2.322 0 0 0 .07.25H2v-63Z" className="colorffdb80 svgShape"></path>
                      <path fill="#2d4b56" d="M59 47H43a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V49a2.006 2.006 0 0 0-2-2Zm-2 16H45V51h12Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M45 51h12v12H45z" className="colore1ebf4 svgShape"></path>
                      <path fill="#2d4b56" d="M59 73H43a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V75a2.006 2.006 0 0 0-2-2Zm-2 16H45V77h12Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M45 77h12v12H45z" className="colore1ebf4 svgShape"></path>
                      <path fill="#2d4b56" d="M33 73H17a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V75a2.006 2.006 0 0 0-2-2Zm-2 16H19V77h12Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M19 77h12v12H19z" className="colore1ebf4 svgShape"></path>
                      <path fill="#2d4b56" d="M37 16h54v4H37zM104 6a9.014 9.014 0 0 0-9 9v6a9 9 0 0 0 18 0v-6a9.014 9.014 0 0 0-9-9Zm5 15a5 5 0 0 1-10 0v-6a5 5 0 0 1 10 0Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M109 15v6a5 5 0 0 1-10 0v-6a5 5 0 0 1 10 0Z" className="colore1ebf4 svgShape"></path>
                      <path fill="#2d4b56" d="M24 6a9.014 9.014 0 0 0-9 9v6a9 9 0 0 0 18 0v-6a9.014 9.014 0 0 0-9-9Zm5 15a5 5 0 0 1-10 0v-6a5 5 0 0 1 10 0Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M29 15v6a5 5 0 0 1-10 0v-6a5 5 0 0 1 10 0Z" className="colore1ebf4 svgShape"></path>
                      <path fill="#2d4b56" d="M87 57.463V49a2 2 0 0 0-2-2H69a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h2.56a35.29 35.29 0 0 1 4.465-4H71V51h12v7.931a34.716 34.716 0 0 1 4-1.468zm26 2.424V49a2 2 0 0 0-2-2H95a2 2 0 0 0-2 2v7.235A35.174 35.174 0 0 1 97 56v-5h12v7.12a34.784 34.784 0 0 1 4 1.767z" className="color2d4356 svgShape"></path>
                      <path fill="#2d4b56" d="M120 16h-3v4h3a4.004 4.004 0 0 1 4 4v12H4V24a4.004 4.004 0 0 1 4-4h3v-4H8a8.01 8.01 0 0 0-8 8v72a8.01 8.01 0 0 0 8 8h56.514a34.692 34.692 0 0 1-1.335-4H8a4.004 4.004 0 0 1-4-4V40h120v28.753a35.128 35.128 0 0 1 4 6.027V24a8.01 8.01 0 0 0-8-8Z" className="color2d4356 svgShape"></path>
                      <path fill="#2d4b56" d="M97 60a31 31 0 1 0 31 31 31.04 31.04 0 0 0-31-31Zm0 58a27 27 0 1 1 27-27 27.028 27.028 0 0 1-27 27Z" className="color2d4356 svgShape"></path>
                      <path fill="#87ddfd" d="M124 91a27 27 0 1 1-27-27 27.028 27.028 0 0 1 27 27Z" className="color81d9e3 svgShape"></path>
                      <path fill="#2d4b56" d="M110.584 78.587 90.058 99.168l-8.642-8.664a2 2 0 0 0-2.832 2.825l10.058 10.084a2.022 2.022 0 0 0 2.832 0l21.942-22a2 2 0 0 0-2.832-2.826Z" className="color2d4356 svgShape"></path>
                      <path fill="#e1eff4" d="M83 51v7.93A35.986 35.986 0 0 0 79 61a35.183 35.183 0 0 0-2.98 2H71V51zm26 0H97v5a34.836 34.836 0 0 1 12 2.12z" className="colore1ebf4 svgShape"></path>
                      <path fill="#ffffff" d="M109 15v2a5 5 0 0 0-10 0v-2a5 5 0 0 1 10 0zm-80 0v2a5 5 0 0 0-10 0v-2a5 5 0 0 1 10 0z" opacity=".5" className="colorffffff svgShape"></path>
                      <path fill="#5996ad" d="M63.17 100H8a3.999 3.999 0 0 1-4-4v-2a3.999 3.999 0 0 0 4 4h54.71c.13.67.29 1.34.46 2Z" opacity=".45" className="colorad9359 svgShape"></path>
                      <path fill="#3f89a5" d="M4 34h120v2H4z" opacity=".5" className="colora54b3f svgShape"></path>
                      <path fill="#d7f0f9" d="M11 20v2H8a3.999 3.999 0 0 0-4 4v-2a3.999 3.999 0 0 1 4-4zm113 4v2a3.999 3.999 0 0 0-4-4h-3v-2h3a3.999 3.999 0 0 1 4 4z" opacity=".3" className="colorf9dbd7 svgShape"></path>
                      <path fill="#d2eef9" d="M4 40h120v2H4z" opacity=".5" className="colorf9edd2 svgShape"></path>
                      <path fill="#ffffff" d="M45 77h12v2H45z" opacity=".5" className="colorffffff svgShape"></path>
                      <path fill="#9fa7aa" d="M45 87h12v2H45z" opacity=".5" className="color9fa5aa svgShape"></path>
                      <path fill="#ffffff" d="M19 77h12v2H19z" opacity=".5" className="colorffffff svgShape"></path>
                      <path fill="#9fa7aa" d="M19 87h12v2H19z" opacity=".5" className="color9fa5aa svgShape"></path>
                      <path fill="#ffffff" d="M45 51h12v2H45z" opacity=".5" className="colorffffff svgShape"></path>
                      <path fill="#9fa7aa" d="M45 61h12v2H45z" opacity=".5" className="color9fa5aa svgShape"></path>
                      <path fill="#ffffff" d="M71 51h12v2H71zM97 51h12v2H97z" opacity=".5" className="colorffffff svgShape"></path>
                      <path fill="#9fa7aa" d="M79 61a35.183 35.183 0 0 0-2.98 2H71v-2Z" opacity=".5" className="color9fa5aa svgShape"></path>
                      <path fill="#e8f7fc" d="M124 91c0 .33-.01.67-.03 1a26.988 26.988 0 0 0-53.94 0c-.02-.33-.03-.67-.03-1a27 27 0 0 1 54 0Z" opacity=".3" className="colore8fbfc svgShape"></path>
                      <path fill="#0f3442" d="M124 91a27 27 0 0 1-54 0c0-.33.01-.67.03-1a26.988 26.988 0 0 0 53.94 0c.02.33.03.67.03 1Z" opacity=".25" className="color0f423c svgShape"></path>
                    </svg>

                  </div>
                  <div
                    className="w-52 bg-white p-5 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:-translate-y-2"
                    style={{ backgroundColor: '#204598', cursor: 'pointer' }} // Added cursor: pointer for visual feedback
                    onClick={() => setActiveSection('feedback')}  
                  >
                    <h2 className="mb-5 text-white font-semibold">Feedback</h2>
                    <svg className=" ml-14 mb-2 w-[65px] h-[55px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="feedback">
                      <path fill="#d9f5ff" d="M55,44.76A2.5,2.5,0,0,0,52.5,42H45V36a4,4,0,0,0-4.13-4A2,2,0,0,0,39,34v.38A11.55,11.55,0,0,1,32,45H28V59h1.88a5.12,5.12,0,0,1,3.62,1.5h0A5.12,5.12,0,0,0,37.12,62H49.36A2.6,2.6,0,0,0,52,59.76,2.5,2.5,0,0,0,49.5,57h.86A2.6,2.6,0,0,0,53,54.76,2.5,2.5,0,0,0,50.5,52h.86A2.6,2.6,0,0,0,54,49.76,2.5,2.5,0,0,0,51.5,47h.86A2.6,2.6,0,0,0,55,44.76Z" className="colord9e2ff svgShape"></path>
                      <rect width="8" height="18" x="20" y="43" fill="#87ddfd" className="color9ca8db svgShape"></rect>
                      <path fill="#67a3ba" d="M24,62a1,1,0,0,1-1-1V55a1,1,0,0,1,2,0v6A1,1,0,0,1,24,62Z" className="color6773ba svgShape"></path>
                      <rect width="11" height="20" x="9" y="42" fill="#67a3ba" className="color6773ba svgShape"></rect>
                      <path fill="#d9f5ff" d="M17,9V21a7,7,0,0,0,7,7h5v6l6-6h5a7,7,0,0,0,7-7V9a7,7,0,0,0-7-7H24A7,7,0,0,0,17,9Z" className="colord9e2ff svgShape"></path>
                      <polygon fill="#4fc2ed" points="32.07 7.51 34.41 11.73 39.14 12.65 35.86 16.18 36.44 20.97 32.07 18.93 27.7 20.97 28.29 16.18 25 12.65 29.73 11.73 32.07 7.51" className="coloredbf4f svgShape"></polygon>
                      <path fill="#385f6d" d="M49.36,63H43a1,1,0,0,1,0-2h6.36A1.62,1.62,0,0,0,51,59.66a1.55,1.55,0,0,0-.38-1.17A1.53,1.53,0,0,0,49.5,58a1,1,0,0,1,0-2h.86A1.62,1.62,0,0,0,52,54.66a1.55,1.55,0,0,0-.38-1.17A1.53,1.53,0,0,0,50.5,53a1,1,0,0,1,0-2h.86A1.62,1.62,0,0,0,53,49.66a1.55,1.55,0,0,0-.38-1.17A1.53,1.53,0,0,0,51.5,48a1,1,0,0,1,0-2h.86A1.62,1.62,0,0,0,54,44.66a1.55,1.55,0,0,0-.38-1.17A1.53,1.53,0,0,0,52.5,43H45a1,1,0,0,1-1-1V36a3,3,0,0,0-3.1-3,1,1,0,0,0-.9,1v.38a12.54,12.54,0,0,1-7.61,11.54A1,1,0,0,1,32,46H29V58h.88a6.12,6.12,0,0,1,4.33,1.79A4.09,4.09,0,0,0,37.12,61H39a1,1,0,0,1,0,2H37.12a6.12,6.12,0,0,1-4.33-1.79A4.09,4.09,0,0,0,29.88,60H28a1,1,0,0,1-1-1V45a1,1,0,0,1,1-1h3.79A10.54,10.54,0,0,0,38,34.38V34a3,3,0,0,1,2.83-3,5,5,0,0,1,3.65,1.41A5,5,0,0,1,46,36v5h6.5a3.51,3.51,0,0,1,2.6,1.15A3.57,3.57,0,0,1,56,44.86a3.48,3.48,0,0,1-1.66,2.58A3.57,3.57,0,0,1,55,49.86a3.48,3.48,0,0,1-1.66,2.58A3.57,3.57,0,0,1,54,54.86a3.48,3.48,0,0,1-1.66,2.58A3.57,3.57,0,0,1,53,59.86,3.61,3.61,0,0,1,49.36,63Z" className="color383e6d svgShape"></path>
                      <path fill="#385f6d" d="M28,62H20a1,1,0,0,1-1-1V50a1,1,0,0,1,2,0V60h6V44H21v2a1,1,0,0,1-2,0V43a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V61A1,1,0,0,1,28,62Z" className="color383e6d svgShape"></path>
                      <path fill="#385f6d" d="M20 63H9a1 1 0 0 1 0-2H19V50a1 1 0 0 1 2 0V62A1 1 0 0 1 20 63zM20 47a1 1 0 0 1-1-1V43H9a1 1 0 0 1 0-2H20a1 1 0 0 1 1 1v4A1 1 0 0 1 20 47z" className="color383e6d svgShape"></path>
                      <path fill="#4fc2ed" d="M14,59a2,2,0,1,1,2-2A2,2,0,0,1,14,59Zm0-2Z" className="coloredbf4f svgShape"></path>
                      <path fill="#385f6d" d="M24 29a8 8 0 0 1-8-8V9a8 8 0 0 1 8-8H40a8 8 0 0 1 8 8v1a1 1 0 0 1-2 0V9a6 6 0 0 0-6-6H24a6 6 0 0 0-6 6V21a6 6 0 0 0 6 6 1 1 0 0 1 0 2zM29 35a.84.84 0 0 1-.38-.08A1 1 0 0 1 28 34V29a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1v3.59l4.29-4.3A1 1 0 0 1 35 27h5a6 6 0 0 0 6-6V19a1 1 0 0 1 2 0v2a8 8 0 0 1-8 8H35.41l-5.7 5.71A1 1 0 0 1 29 35zM47 16a1 1 0 0 1-1-1V14a1 1 0 0 1 2 0v1A1 1 0 0 1 47 16z" className="color383e6d svgShape"></path>
                      <path fill="#385f6d" d="M36.44,22a1,1,0,0,1-.42-.09l-4-1.85-3.95,1.85a1,1,0,0,1-1-.1,1,1,0,0,1-.4-.93l.52-4.33-3-3.19a1,1,0,0,1,.54-1.66l1.05-.2a1,1,0,0,1,1.17.79,1,1,0,0,1-.23.85L29,15.5a1,1,0,0,1,.26.8l-.36,3L31.65,18a1,1,0,0,1,.84,0l2.74,1.28-.36-3a1,1,0,0,1,.26-.8l2-2.21-3-.58a1,1,0,0,1-.68-.5L32.07,9.57l-1.28,2.32a1,1,0,0,1-1.36.4A1,1,0,0,1,29,10.93L31.2,7A1,1,0,0,1,33,7l2.11,3.82,4.27.83a1,1,0,0,1,.76.67,1,1,0,0,1-.22,1l-3,3.19.52,4.33a1,1,0,0,1-1,1.12Z" className="color383e6d svgShape"></path>
                    </svg>
                  </div>

                  <div
                    className="w-52 bg-white p-5 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:-translate-y-2"
                    style={{ backgroundColor: '#204598', cursor: 'pointer' }}
                    onClick={openPopup}
                  >
                    <h2 className="mb-5 text-white font-semibold">Upload Sign</h2>
                    <svg className=" ml-14 pt-2 w-[65px] h-[55px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="upload">
                      <path fill="#87ddfd" d="M2 56h60v3a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-3Z" className="colorffffff svgShape"></path>
                      <path fill="#82ddff" d="M39 30.12v-5a2 2 0 0 1 2-2h4a4 4 0 0 0 2.86-6.76l-13-13.2a4 4 0 0 0-5.72 0l-13 13.2a4 4 0 0 0 2.86 6.8h4a2 2 0 0 1 2 2V48a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4v-9.92" className="colorffe082 svgShape"></path>
                      <path d="M57 56v6h3a2 2 0 0 0 2-2v-4zm-9.15-39.6-13-13.2a4 4 0 0 0-5.36-.3 3.37 3.37 0 0 1 .36.3l13 13.2A4 4 0 0 1 40 23.16h-4a2 2 0 0 0-2 2V48a4 4 0 0 1-4 4h5a4 4 0 0 0 4-4V25.15a2 2 0 0 1 2-2h4a4 4 0 0 0 2.85-6.75z" opacity=".1" fill="#000000" className="color000000 svgShape"></path>
                      <circle cx="10" cy="48" r="2" fill="#144e64" className="color1b1464 svgShape"></circle>
                      <path fill="#144e64" d="M62 46a2 2 0 0 0-2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V48a2 2 0 0 0-4 0v10c0 3.31 2.69 6 8 6h52c1.31 0 4-2.69 4-6V48a2 2 0 0 0-2-2Z" className="color1b1464 svgShape"></path>
                      <path fill="#144e64" d="M19 25.16h4V48a6 6 0 0 0 6 6h6a6 6 0 0 0 6-6v-9.92a2 2 0 0 0-4 0V48a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V25.15a4 4 0 0 0-4-4h-4a2 2 0 0 1-1.85-1.22 1.92 1.92 0 0 1 .42-2.14l13-13.2a2.06 2.06 0 0 1 2.86 0l13 13.2a1.92 1.92 0 0 1 .42 2.14A2 2 0 0 1 45 21.16h-4a4 4 0 0 0-4 4v5a2 2 0 1 0 4 0v-5h4A6 6 0 0 0 49.28 15l-13-13.21a6 6 0 0 0-8.58 0L14.72 15A6 6 0 0 0 19 25.16Z" className="color1b1464 svgShape"></path>
                    </svg>
                  </div>

                  {isPopupVisible && (
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                        <h3 className="text-xl font-semibold mb-4">Upload Your Image</h3>
                        <form onSubmit={handleSubmit}>
                          <input
                            type="file"
                            accept="image/*"
                            className="mb-4"
                            onChange={handleFileChange}
                            required
                          />
                          {filePreview && (
                            <div className="mb-4">
                              <img
                                src={filePreview}
                                alt="Preview"
                                className="w-full h-auto"
                              />
                            </div>
                          )}
                          <div className="flex justify-between">
                            <button
                              type="submit"
                              className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="px-4 py-2 bg-red-500 text-white rounded-md transition-colors duration-300 hover:bg-red-600"
                              onClick={closePopup}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                </div>
              </div>



                   
              <h2 className="text-2xl font-bold mt-12 mb-8 text-left ml-[10%]">Account Details</h2>
              <table className="table-auto w-[70%] ml-[10%] border-collapse border border-gray-300">

                <thead>
                  <tr>
                    <th className="border border-gray-300 px-4 py-2">Field</th>
                    <th className="border border-gray-300 px-4 py-2">Value</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
              <td className="border border-gray-300 px-4 py-2">Name</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.name || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Email</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.email || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Department</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.department || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">College </td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.company || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Designation</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.designation || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Address</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.address || 'N/A'}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Phone Number</td>
              <td className="border border-gray-300 px-4 py-2">{facultyDetails?.phoneNumber || 'N/A'}</td>
            </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}


        {activeSection === 'dashboard' && (
          <div className="flex flex-col min-h-screen bg-gray-100">
            {/* Go Back Button */}
            <div className="flex  p-4"
              style={{ marginLeft: '80%' }}>
              <button
                onClick={() => setActiveSection('account')}
                className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600"

              >
                Go Back
              </button>
            </div>

            {/* Main Content */}
            <main className="flex-1 p-6 pt-0 ml-2">
              <div className="container ml-8 w-[80%] bg-white shadow-md rounded-lg p-6 pt-0">

                <div>
                  <h2 className="text-xxl font-semibold mb-6  text-center" style={{ fontSize: '30px', fontWeight: 'bolder' }}>Students Day Logs</h2>
                </div>

                {/* Loading and Error States */}
                {loading && <p>Loading...</p>}
                {error && <p className="text-red-600">{error}</p>}

                {/* User and Log Sections */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Users List */}
                  <div className="bg-blue-50 p-4 rounded-lg w-[90%] h-full max-h-screen shadow-sm overflow-y-auto"
                    style={{ height: '80vh', overflowY: 'auto' }}>
                    <h3 className="text-lg font-semibold text-blue-600">Users</h3>
                    <ul>
                      {users.length > 0 ? (
                        users.map((user, index) => (
                          <li
                            key={user.id}
                            className="cursor-pointer mb-2 p-2 border rounded-lg shadow-sm"
                            style={{
                              backgroundColor: selectedUser?.id === user.id ? '#204598' : (index % 2 === 0 ? 'white' : 'white'),
                              color: selectedUser?.id === user.id ? 'black' : 'black',
                            }}
                            onClick={() => {
                              setSelectedUser(user);
                              setDailyLogs([]); // Clear dailyLogs when user changes
                              setSelectedLog(null); // Clear selectedLog when user changes
                            }}
                          >
                            <p className={`font-semibold ${selectedUser?.id === user.id ? 'text-white' : 'text-black'}`} style={{ wordBreak: 'break-word' }}>
                              {user.name || 'No Name'} : {user.semester || 'No Name'}
                            </p>
                            <p className={`font-semibold ${selectedUser?.id === user.id ? 'text-white' : 'text-black'}`} style={{ wordBreak: 'break-word' }}>
                              {user.usn} : {user.phoneNumber}
                            </p>


                            {/* Export Button */}
                          <button
  className="px-2 py-1 bg-green-500 text-white rounded-md text-sm transition-colors duration-300 hover:bg-green-600"
  onClick={() => exportLogs(user.id)}
>
  Export
</button>
                          </li>
                        ))
                      ) : (
                        <p className="text-black">No users found.</p>
                      )}
                    </ul>
                  </div>





                  {/* Daily Logs */}
                  <FacultyCalendarComponent
      selected ={selectedUser }
      dailyLogs={dailyLogs}
                    attendance={attendance}
    />
                </div>
              </div>
            </main>
          </div>
        )}



{activeSection === 'attendance' && (
          <div className="flex flex-col min-h-screen bg-gray-100"> {/* Adjust max-width here */}
            <div className="flex  p-4"
              style={{ marginLeft: '80%' }}>
              <button
                onClick={() => setActiveSection('account')}
                className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600"

              >
                Go Back
              </button>
            </div>


            <main className="flex-1 p-6 pt-0 ml-2">
              <div className="container ml-8 w-[80%] bg-white shadow-md rounded-lg p-6 pt-0">

                <div>
                  <h2 className="text-xxl font-semibold mb-6  text-center" style={{ fontSize: '30px', fontWeight: 'bolder' }}>Students Attendance</h2>
                </div>

                {/* Loading and Error States */}
                {loading && <p>Loading...</p>}
                {error && <p className="text-red-600">{error}</p>}

                {/* User and Log Sections */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Users List */}
                  <div className="bg-blue-50 p-4 rounded-lg w-[90%] h-full max-h-screen shadow-sm overflow-y-auto"
                    style={{ height: '80vh', overflowY: 'auto' }}>
                    <h3 className="text-lg font-semibold text-blue-600">Users</h3>
                    <ul>
                      {users.length > 0 ? (
                        users.map((user, index) => (
                          <li
                            key={user.id}
                            className="cursor-pointer mb-2 p-2 border rounded-lg shadow-sm"
                            style={{
                              backgroundColor: selectedUser?.id === user.id ? '#204598' : (index % 2 === 0 ? 'white' : 'white'),
                              color: selectedUser?.id === user.id ? 'black' : 'black',
                            }}
                            onClick={() => {
                              setSelectedUser(user);
                              setAttendance([]); // Clear dailyLogs when user changes
                              setSelectedLog(null); // Clear selectedLog when user changes
                            }}
                          >
                            <p className={`font-semibold ${selectedUser?.id === user.id ? 'text-white' : 'text-black'}`} style={{ wordBreak: 'break-word' }}>
                              {user.name || 'No Name'} : {user.semester || 'No Name'}
                            </p>
                            <p className={`font-semibold ${selectedUser?.id === user.id ? 'text-white' : 'text-black'}`} style={{ wordBreak: 'break-word' }}>
                              {user.usn} : {user.phoneNumber}
                            </p>
                          </li>
                        ))
                      ) : (
                        <p className="text-black">No users found.</p>
                      )}
                    </ul>
                  </div>





                 
                  <FacultyAttendanceComponent
      selected ={selectedUser }
      attendance={attendance}
                     dailyLogs={dailyLogs}
                 
    />
                </div>
              </div>
            </main>



            


            

            
            
               
            
          </div>
        )}






         {activeSection === 'signature' && (
              <div className="flex flex-col min-h-screen bg-gray-100">
                {/* Go Back Button */}
                <div className="flex  p-4"
                  style={{ marginLeft: '80%' }}>
                  <button
                    onClick={() => setActiveSection('account')}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600"

                  >
                    Go Back
                  </button>
                </div>

                {/* Main Content */}
                <main className="flex-1 p-6 pt-0 ml-2">



                  <SignatureBackgroundRemover />


                </main>
              </div>
            )}

{activeSection === 'feedback' && (
              <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex p-4" style={{ marginLeft: '80%' }}>
        <button
          onClick={goBack}
          className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600"
        >
          Go Back
        </button>
      </div>

      <main className="flex-1 p-6 pt-0 ml-2">
        <div className="container ml-8 w-[80%] bg-white shadow-md rounded-lg p-6 pt-0">
          <h2 className="text-xxl font-semibold mb-6 text-center" style={{ fontSize: '30px', fontWeight: 'bolder' }}>
            Students Feedback
          </h2>

          {/* User List */}
          <div className="grid grid-cols-1 gap-6">
            <div className="bg-blue-50 p-4 rounded-lg w-[90%] h-full max-h-screen shadow-sm overflow-y-auto" style={{ height: '80vh', overflowY: 'auto' }}>
              <h3 className="text-lg font-semibold text-blue-600">Users</h3>
              <table className="min-w-full mt-4 border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2">Student ID</th>
                    <th className="border border-gray-300 px-4 py-2">Student Name</th>
                    <th className="border border-gray-300 px-4 py-2">USN</th>
                    <th className="border border-gray-300 px-4 py-2">Sem</th>
                    <th className="border border-gray-300 px-4 py-2">Email ID</th>
                    <th className="border border-gray-300 px-4 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
            <tr key={user.id}>
              <td>{user.IIMSTC_ID}</td>
              <td>{user.name}</td>
              <td>{user.usn}</td>
              <td>{user.semester}</td>
              <td>{user.email}</td>
              <td>
                <button
  onClick={() =>
    handleShowFeedbackForm(user.id, user.Facultyfeedback)
  }
  style={{
    color: user.Facultyfeedback ? 'green' : 'red',
  }}
>
  {user.Facultyfeedback ? 'View Feedback' : 'Give Feedback'}
</button>

                {showFeedbackForms[user.id] && (
                  <FeedbackFormFaculty
                    userId={user.id}
                    onClose={() => handleHideFeedbackForm(user.id)}
                    isViewMode={viewOnlyFeedback[user.id]} // Pass the view-only state
                  />
                )}
              </td>
            </tr>
          ))}
                </tbody>
              </table>
            </div>
          </div>

         {Object.keys(showFeedbackForms).map((userId) => {
  const user = users.find((u) => u.id.toString() === userId);
  if (user && showFeedbackForms[user.id]) {
    return (
      <div key={user.id}>
        <FeedbackFormFaculty
  userId={user.id}  // Pass userId here
  onClose={() => handleHideFeedbackForm(user.id)}
  isViewMode={viewOnlyFeedback[user.id]}        
/>
      </div>
    );
  }
  return null;
})}

        </div>
      </main>
    </div>
            )}
        {isHelpOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-md shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Help</h2>
                <button
                  onClick={() => setIsHelpOpen(false)}
                  className="text-gray-500"
                  aria-label="Close Help"
                >
                  <AiOutlineClose size={24} />
                </button>
              </div>
              <p className="text-black">If you have any questions or need assistance, please contact support.</p>

            </div>
          </div>
        )}


        <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
          {isChatbotOpen ? "Close Chatbot" : "Help?"}
        </button>

        {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
      </div>
    </div>
    </div>
    </>
  );
};

export default FacultyDashboard;
