import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Chatbot from '../components/Chatbot';
import { fetchUserProfile, updateUserProfile, UserProfile } from '../services/userService';
import '../styles/student.css';
import axios from 'axios';
import { CiLogout } from 'react-icons/ci';
import { AiOutlineUser, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'; // Import AiOutlineEdit
import { MdAccountBox, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { FaCalendar, FaTools, FaBars } from 'react-icons/fa';
import { IoSchoolOutline } from "react-icons/io5";
import { BsXbox } from "react-icons/bs";
import { FaFileUpload } from "react-icons/fa";
import InternshipProgram from '../components/InternshipProgram';
import NormInternship from '../components/NormInternship';
import AccountDetails from '../components/AccountDetails';

import PaymentComponent from '../components/PaymentComponent';
import FullCalendarComponent from '../components/FullCalendarComponent';
import PreScreening from '../components/PreScreening';
import settingsImage from '../img/settings_10024262.jpg';
import userImage from '../img/account_12099245 (1).png';
import logoutImage from '../img/log-out_10117545.png';
import internshipImage from '../img/internship_8920823.png';
import internshipIcon from '../img/internship_16648163.png';
import { IoMdGlobe } from "react-icons/io";
import { MdOutlineSchool } from "react-icons/md";
import annualReportIcon from '../img/annual-report_17340453.png';
import pen from '../img/pen_10977638.png';
import info from '../img/information-point_11606639 (1).png';
import saikiranPDF from '../img/saikiran.pdf';
import { FaBell, FaQuestionCircle, FaEnvelope } from 'react-icons/fa';
import { FaGlobe } from "react-icons/fa6";
import { FaUserGraduate } from "react-icons/fa6";
import { RiSecurePaymentFill } from "react-icons/ri";
import LeaveRequestForm from '../components/leave';
import { FcLeave } from "react-icons/fc";
import { FaClipboardCheck } from 'react-icons/fa';
import { TbLogout2 } from "react-icons/tb";
import Flowchart from '../components/Flowchart';
import NotificationBell from '../components/NotificationBell';


interface Notification {
  formName: string;
  message: string;
  read: boolean;
}


const TestAttendingMode = () => {
   const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
const [userId, setUserId] = useState('');
  const [formData, setFormData] = useState<{ userId?: string }>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false); // Track editing state
  const [editProfile, setEditProfile] = useState<UserProfile | null>(null);
  const [activeSection, setActiveSection] = useState<'account' | 'internshipProgram' | 'Flowchart' | 'normInternship' |'UploadPDF'| 'signupload' | 'aicteIntern' | 'payment' | 'calendar' | 'leaverequest' | 'preScreening'>('account');
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [showAssessmentButtons, setShowAssessmentButtons] = useState(false);
const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  const [aicteFormSubmitted, setAicteFormSubmitted] = useState(false);
   const [internshipCompleted, setInternshipCompleted] = useState(false);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };
  const handleGenerateReport = () => {
    const link = document.createElement('a');
    link.href = saikiranPDF;
    link.download = 'Saikiran.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleTakeAssessment = () => {
    navigate('/test');
  };

  const handleGiveFeedback = () => {
    setFeedbackGiven(true);
    navigate('/stu');
  }
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const user = await fetchUserProfile();
        if (user) {
          setUserProfile(user);
        } else {
          setError('Error fetching user profile. Please try again.');
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching user profile. Please try again.');
        setLoading(false);
        console.error('Error fetching user profile:', error);
      }
    };

    fetchProfile();
  }, []);
useEffect(() => {
  const fetchUserId = async () => {
    try {
      const IIMSTC_ID = localStorage.getItem('IIMSTC_ID'); // Retrieve IIMSTC_ID from local storage
      if (IIMSTC_ID) {
        const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
        setFormData((prevFormData) => ({
          ...prevFormData,
          userId: response.data.userId, // Set userId from response
        }));
        setUserId(response.data.userId); // Set the userId separately if needed elsewhere
      }
    } catch (error) {
      console.error('Error fetching user ID', error);
    }
  };

  fetchUserId();
}, []);

  useEffect(() => {
    if (location.pathname.includes('normInternship')) {
      setActiveSection('normInternship');
    }
  }, [location.pathname]);

  const handleEdit = () => {
    setIsEditing(true);
    setEditProfile(userProfile);
  };

  const handleSave = async () => {
    if (editProfile) {
      try {
        const updatedProfile = await updateUserProfile(editProfile);
        setUserProfile(updatedProfile.user);
        setIsEditing(false);
      } catch (error) {
        setError('Error updating profile. Please try again.');
        console.error('Error updating profile:', error);
      }
    }
  };
  const handleProfileUpdate = (updatedProfile: UserProfile) => {
    setUserProfile(updatedProfile);
    setEditProfile(updatedProfile);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editProfile && 'target' in e) {
      const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
      setEditProfile({
        ...editProfile,
        [name]: value
      });
    }
  };

  const handleSidebarClick = (section: 'account' | 'internshipProgram' | 'Flowchart' | 'normInternship' | 'aicteIntern' | 'UploadPDF'| 'payment' | 'calendar' | 'preScreening' | 'leaverequest') => {
    if (section === 'internshipProgram') {
      if (userProfile?.InternshipApproved && userProfile?.paymentCompleted) {
        setActiveSection('aicteIntern');
      } else if (userProfile?.InternshipApproved && !userProfile?.paymentCompleted) {
        setActiveSection('payment');
      } else {
        setActiveSection('preScreening');
      }
    } else {
      setActiveSection(section);
    }
  };

  const handleAicteFormSubmit = () => {
    setUserProfile((prevUserProfile) => {
      if (prevUserProfile) {
        return { ...prevUserProfile, aicteFormSubmitted: true };
      } else {
        return null;
      }
    });
    setActiveSection('calendar');
  };

  const handlePaymentComplete = (): Promise<void> => {
    setUserProfile((prevUserProfile) => {
      if (prevUserProfile) {
        return { ...prevUserProfile, paymentCompleted: true };
      } else {
        return null;
      }
    });
    return Promise.resolve();
  };

  const isFile = (photo: any): photo is File => photo instanceof File;

  const isBuffer = (photo: any): photo is { type: 'Buffer'; data: number[] } =>
    photo && photo.type === 'Buffer' && Array.isArray(photo.data);

  const renderPassportPhoto = (passportPhoto: File | { type: 'Buffer'; data: number[] } | null | undefined) => {
    if (!passportPhoto) {
      return 'No photo available';
    }

    if (isFile(passportPhoto)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        if (dataUrl) {
          return <img src={dataUrl} alt="Passport Photo Preview" className="imgPreview" />;
        }
      };
      reader.readAsDataURL(passportPhoto);
      return null; // The image will be displayed once FileReader finishes loading
    } else if (isBuffer(passportPhoto)) {
      const base64String = btoa(String.fromCharCode(...passportPhoto.data));
      const dataUrl = `data:image/jpeg;base64,${base64String}`;
      return <img src={dataUrl} alt="Passport Photo Preview" className="imgPreview" />;
    } else {
      return 'No photo available';
    }

  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [isProgramsDropdownOpen, setIsProgramsDropdownOpen] = useState(false);
  const [isInternshipDropdownOpen, setIsInternshipDropdownOpen] = useState(false);

  const toggleProgramsDropdown = () => {
    setIsProgramsDropdownOpen(!isProgramsDropdownOpen);
  };

  const toggleInternshipDropdown = () => {
    setIsInternshipDropdownOpen(!isInternshipDropdownOpen);
  };


  const bodyStyle = {
    backgroundColor: '#f0f0f0', // Change this to your desired color
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'; // Set background color to white

    // Clean up
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");

  const handleSectionClick = (section: string) => {
    setSelectedSection(section);
    setIsMobileSidebarOpen(false); // Hide the sidebar after clicking any button
  };

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };


   const [notifications, setNotifications] = useState<Notification[]>([
    { formName: 'Form 1', message: 'New submission received', read: false },
    { formName: 'Form 2', message: 'Review pending', read: false },

  ]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const markAsRead = (index: number) => {
    setNotifications(prevNotifications =>
      prevNotifications.map((notification, i) =>
        i === index ? { ...notification, read: true } : notification
      )
    );
  };

  const markAllAsRead = () => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification => ({ ...notification, read: true }))
    );
  };

  const unreadCount = notifications.filter(notification => !notification.read).length;
const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      if (file.size > 50 * 1024 * 1024) {
        alert('File size exceeds 50MB limit.');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    const input = document.getElementById('signedReport') as HTMLInputElement;
    if (input) input.value = ''; // Clear file input
  };

 const handleSubmit = async (event: React.FormEvent) => {
  event.preventDefault();

  if (!selectedFile) {
    alert('Please upload a file.');
    return;
  }

  if (!formData.userId) {
    alert('User ID is missing.');
    return;
  }

  setIsSubmitting(true);

  try {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('userId', formData.userId); // Add user ID
    formDataToSubmit.append('pdf', selectedFile); // Add file

    const response = await fetch('/api/pdf/create', {
      method: 'POST',
      body: formDataToSubmit,
    });

    if (response.ok) {
      const data = await response.json();
      alert(`File uploaded successfully`);
    } else {
      const errorData = await response.json();
      alert(`File upload failed: ${errorData.message}`);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    alert('An error occurred while uploading the file.');
  } finally {
    setIsSubmitting(false);
  }
};







  return (
    <>
      {/* Navigation Bar */}
      <nav className="bg-blue-600 text-white p-4 flex justify-between items-center fixed top-0 left-0 w-full shadow-lg z-50">
        {/* Toggle button for mobile devices */}
        <button
          className="block md:hidden text-white text-2xl"
          onClick={toggleMobileSidebar} // updated function name
        >
          <FaBars />
        </button>

        {/* Logo */}
        {/* Place your logo here */}

        <div className="flex space-x-6 ml-auto pl-2"> {/* ml-auto aligns icons to the right */}
          {/* Notification Icon with Tooltip */}
            <div className="relative">
            {/* Bell icon with notification count */}
            <div onClick={() => setIsDropdownVisible(!isDropdownVisible)} className="relative cursor-pointer">
              <FaBell className="text-xl hover:text-gray-300" />
              {unreadCount > 0 && (
                <span className="absolute top-[-9px] right-[-8px] bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  {unreadCount}
                </span>
              )}
            </div>

            {/* Dropdown with notifications */}
            {isDropdownVisible && (
              <NotificationBell
                notifications={notifications}
                markAsRead={markAsRead}
                markAllAsRead={markAllAsRead}
              />
            )}
          </div>

          {/* Help Icon with Tooltip */}
          <div className="relative group">
            <FaQuestionCircle className="text-xl cursor-pointer hover:text-gray-300" />
            <div className="absolute right-full mr-2 top-6 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Help
            </div>
          </div>

          {/* Message Icon with Tooltip */}
          <div className="relative group">
            <FaEnvelope className="text-xl cursor-pointer hover:text-gray-300" />
            <div className="absolute right-full mr-2 top-6 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Message
            </div>
          </div>

          {/* Logout Icon with Tooltip */}
          <div className="relative group">
            <TbLogout2
              onClick={handleLogout}
              className="text-xl cursor-pointer hover:text-gray-300 transform hover:scale-105 transition duration-200"
            />
            <div className="absolute right-full mr-2 top-8 hidden group-hover:flex items-center justify-center bg-black text-white text-xs rounded py-1 px-2">
              Logout
            </div>
          
          </div>
        </div>




      </nav>


      <div className="pt-16"> {/* Add padding to ensure content doesn't hide under the navbar */}
        <div className={`relative flex ${isSidebarMinimized ? 'lg:ml-16' : 'lg:ml-64'} transition-all duration-300`}>

          {/* Sidebar */}
          <div
            className={`bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-[94%] md:h-[90%] z-10 left-0 w-[16rem] sm:w-[16rem] ${isMobileSidebarOpen ? "block" : "hidden"} md:block`}
            style={{
              background: "linear-gradient(45deg, rgb(7, 21, 35), rgb(0, 25, 50))",
              borderRadius: "20px",
            }}
          >
            {/* Top Section: Logo and User Info */}
            <div className="flex flex-col items-center sm:flex flex-grow">
              <img
                src="https://iimstc.com/wp-content/uploads/2021/10/log.png"
                alt="Logo"
                className="h-12 mb-6"
              />

              {/* Passport Photo */}
              {userProfile?.passportPhoto && 'data' in userProfile.passportPhoto && (
                <img
                  src={`data:image/jpeg;base64,${btoa(
                    String.fromCharCode(...new Uint8Array(userProfile.passportPhoto.data))
                  )}`}
                  alt="Passport"
                  className="h-24 w-24 md:h-32 md:w-32 object-cover rounded-full"
                />
              )}

              {/* User Name */}
              <h2 className="text-lg md:text-xl font-bold text-white mt-4 text-center">
                Hi, {userProfile?.name}
                
              </h2>
             
            

              {/* Sidebar Buttons */}
              <div className="flex flex-col mt-2">
                <button
                  onClick={() => {
                    handleSectionClick("account");
                    handleSidebarClick("account");
                  }}
                  className={`flex items-center p-2 rounded-lg transition-all duration-300 ${selectedSection === "account" ? "border-b-2 bg-green-500 " : ""
                    }`}
                >
                  <AiOutlineUser size={30} className="sm:mr-2" />Account
                </button>

            
{userProfile?.aicteFormSubmitted && (
  <>
    <button
      onClick={() => {
        handleSectionClick("calendar");
        handleSidebarClick("calendar");
      }}
      // Disable if internship is completed
      className={`flex items-center p-2 rounded-lg transition-all duration-300 ${
        selectedSection === "calendar" 
          ? "border-b-2 bg-green-500" 
         
            : ""
      }`}
    >
      <FaCalendar size={25} className="sm:mr-2" /> Daily Logs
    </button>

    <button
      onClick={() => {
        handleSectionClick("leaverequest");
        handleSidebarClick("leaverequest");
      }}
      className={`flex items-center p-2 rounded-lg transition-all duration-300 ${
        selectedSection === "leaverequest" 
          ? "border-b-2 bg-green-500" 
          : ""
      }`}
    >
      <FcLeave size={25} className="sm:mr-2" /> Leave Request
    </button>
  </>
)}

                <button
                  onClick={() => {
                    handleSectionClick("UplaodPDF");
                    handleSidebarClick("UploadPDF");
                  }}

                  className={`flex items-center p-2 rounded-lg transition-all duration-300 ${selectedSection === "UplaodPDF" ? "border-b-2 bg-green-500" : ""
                    }`}
                >
                 <FaFileUpload size={25} className="sm:mr-2" /> Upload PDF
                </button>
{userProfile?.internshipCompleted && (
  <>   
    <button
      onClick={() => {
        handleSectionClick("Flowchart");
        handleSidebarClick("Flowchart");
      }}
      className={`flex items-center p-2 rounded-lg transition-all duration-300 ${
        selectedSection === "Flowchart" ? "border-b-2 bg-green-500" : ""
      }`}
    >
      <FaClipboardCheck size={25} className="sm:mr-2" /> Complete Internship
    </button>
  </>
)}

              </div>
            </div>



          </div>










          {/* Main Content */}
          <div className="flex-1 w-full max-w-full p-3 sm:p-6 md:w-[90%] md:ml-56 sm:ml-[14px] ml-0">
            <div className="w-full sm:w-full md:w-[90%] max-w-full">


              {activeSection === 'account' && userProfile && (
                <AccountDetails
                  userProfile={userProfile}
                  isEditing={isEditing}
                  editProfile={editProfile ?? null}
                  handleChange={handleChange}
                  handleSave={handleSave}
                  handleEdit={handleEdit}
                  onProfileUpdate={handleProfileUpdate}
               
                />
              )}
              {activeSection === 'internshipProgram' && (
                <div>
                  <div className="flex justify-between mb-4">
                    {(!userProfile?.InternshipApproved || !userProfile?.paymentCompleted) && (
                      <button
                        onClick={() => handleSidebarClick('preScreening')}
                        className="bg-teal-600 text-white font-semibold py-2 px-4 rounded hover:bg-teal-500 transition"
                        aria-label="Complete Pre-Screening"
                      >
                        Complete Pre-Screening
                      </button>
                    )}
                  </div>
                  <PreScreening />
                </div>
              )}
              {activeSection === 'normInternship' && (
                <>
                  <div className="flex justify-between items-center mb-6">

                  </div>
                  <NormInternship />
                  <button
                    onClick={() => handleSidebarClick('aicteIntern')}
                    className="bg-teal-600 text-white font-semibold py-2 px-4 rounded hover:bg-teal-500 transition"
                    aria-label="Enroll Now"
                    style={{ marginLeft: '7.5%', marginRight: '0%' }}
                  >
                    Enroll Now
                  </button>
                </>
              )}
              {activeSection === 'payment' && !userProfile?.paymentCompleted && userProfile && (
                <PaymentComponent userProfile={userProfile} onPaymentComplete={handlePaymentComplete} />
              )}
              {activeSection === 'calendar' && <FullCalendarComponent />}

              {activeSection === 'preScreening' && <PreScreening />}
              {activeSection === 'leaverequest' && <LeaveRequestForm />}
              {activeSection === 'Flowchart' && <Flowchart />}
               {activeSection === 'UploadPDF' && (
                <div className="flex flex-col min-h-screen bg-gray-100">
                  {/* Go Back Button */}
                  <div className="flex justify-between p-4">
                    <div className="flex-1"></div> {/* Filler space to push button to the right */}
                    <button
                      onClick={() => setActiveSection('account')}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600 ml-auto"
                    >
                      Go Back
                    </button>
                  </div>

                  {/* Header Section */}
                  <div className="flex justify-between p-4">
                    <div className="flex-1"></div> {/* Pushes the button to the right */}
                  </div>

                  {/* Main Content */}
                  <main className="flex-1 p-6 pt-0 ml-2">
                    <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-xl font-semibold mb-4 text-center">Upload Signed Report</h2>
                     <form onSubmit={handleSubmit}>
      {/* File Upload */}
      <div className="mb-4">
        <label
          htmlFor="signedReport"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Signed Report (PDF Only)
        </label>
        <input
          type="file"
          id="signedReport"
          accept="application/pdf"
          onChange={handleFileChange}
          className="w-full px-4 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={handleClear}
          className="px-4 py-2 bg-gray-500 text-white rounded-md transition-colors duration-300 hover:bg-gray-600"
        >
          Clear
        </button>

        <button
          type="submit"
          className={`px-4 py-2 bg-blue-500 text-white rounded-md transition-colors duration-300 hover:bg-blue-600 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
                    </div>
                  </main>
                </div>
              )}
            </div>
          </div>


          


          {isHelpOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-md shadow-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Help</h2>
                  <button
                    onClick={() => setIsHelpOpen(false)}
                    className="text-gray-500"
                    aria-label="Close Help"
                  >
                    <AiOutlineClose size={24} />
                  </button>
                </div>
                <p>If you have any questions or need assistance, please contact support.</p>
              </div>
            </div>
          )}

          <button
            onClick={handleChatbotToggle}
            className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg"
          >
            {isChatbotOpen ? 'Close Chatbot' : 'Help?'}
          </button>

          {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
        </div>
      </div>
    </>
  );

};
export default TestAttendingMode;
