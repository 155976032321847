import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Management from './pages/Management';
import TestAttendingMode from './pages/TestAttendingMode';
import FetchUserPhoto from './pages/FetchUserPhoto';
import AdminDashboard from './pages/AdminDashboard';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import FinanceDashboard from './pages/FinanceDashboard';
import InternshipDashboard from './pages/InternshipDashboard';
import ManagementDashboard from './pages/ManagementDashboard';
import FacultyDashboard from './pages/FacultyDashboard';
import CreateAdmin from './pages/Create';

import InternshipProgram from './components/InternshipProgram';
import Graduate from './components/Graduate';

import PreScreening from './components/PreScreening';
import  Reg from './pages/Reg';
import IndustryDashboard from './pages/IndustryDashboard';
import LandingPage from './components/LandingPage';
import Form from './components/Form';
import OtpForm from './components/OtpForm';
import Log from './components/Log';
import Test from './components/Test';
import StudentFeedback from './components/StudentFeedback';
import AccessForm from './components/accessForm';
import Normal from './components/Normal';

import Successfully from './components/successfully';

 // Corrected import

const App: React.FC = () => {
  return (
    
      <Router>
        <Routes>
          <Route path="/general_register/app04login" element={<Normal pageSource="VTU" />} />
        <Route path="/general_register/app02login" element={<Normal pageSource="Edify" />} />
      <Route path="/general_register/app03login" element={<Normal pageSource="TIE" />} />
       <Route path="/general_register/app01login" element={<Normal pageSource="Dr Brijesh" />} />
       
         <Route path="/general_register/app05login" element={<Normal pageSource="Cold" />} />
         <Route path="/general_register/app06login" element={<Normal pageSource="self" />} />
          <Route path="/" element={<LandingPage />} />

          <Route path="/register/iimstc_v01" element={<Form pageSource="VTU" />} />
        <Route path="/register/iimstc_v02" element={<Form pageSource="Edify" />} />
        <Route path="/register/iimstc_v03" element={<Form pageSource="TIE" />} />
        <Route path="/register/iimstc_v04" element={<Form pageSource="Dr Brijesh" />} />
       
         <Route path="/register/iimstc_v05" element={<Form pageSource="Cold" />} />
          <Route path="/register/e" element={<Form pageSource="e" />} />
          <Route path="/user" element={<TestAttendingMode />} />
          
          <Route path="/otp" element={<OtpForm />} />
          <Route path="/log" element={<Log />} />
          <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/finance" element={<FinanceDashboard />} />
                <Route path="/internship" element={<InternshipDashboard />} />
                <Route path="/management" element={<ManagementDashboard />} />
                <Route path="/faculty" element={<FacultyDashboard />} />
                <Route path="/create-admin" element={<CreateAdmin />} />
                
                <Route path="/graduate" element={<Graduate />} />
                <Route path="/internships" element={<InternshipProgram />} />
        <Route path="/pre" element={<PreScreening />} />
        <Route path="/reg" element={<Reg />} />
        <Route path="/industry" element={<IndustryDashboard />} />
        <Route path="/test" element={<Test/>} />
         <Route path="/stu" element={<StudentFeedback/>} />
         <Route path="/acc" element={<AccessForm />} />
          <Route path="/success" element={<Successfully />} />
        
         
          
          
          
        
          
        </Routes>
      </Router>
    
  );
};

export default App;
