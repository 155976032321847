import axios from 'axios';




// Function to log in a user
export const loginUser = async (loginData: { IIMSTC_ID: string; password: string }) => {
  try {
    const response = await axios.post(`/api/users/login`, loginData);
    const { token, user } = response.data;

    // Store token and user details in localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('IIMSTC_ID', user.IIMSTC_ID);
    localStorage.setItem('degreeStatusId', user.degreeStatusId);

    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Function to verify OTP
export const verifyOTP = async (email: string | null, otp: string) => {
  if (!email) throw new Error('Email is missing');
  
  const response = await axios.post(`api/users/verify-otp`, { email, otp });
  return response.data; // Assuming backend sends the message as part of response
};


// Function to register a new user
export const completeRegistration = async (formData: FormData) => {
  try {
    const response = await axios.post(`/api/users/register`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error registering user:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', (error as Error).message);
    }
    throw error;
  }
};
// Fetch all colleges
export const fetchColleges = async () => {
  try {
    const response = await axios.get('/api/college/colleges');
    return response.data;
  } catch (error) {
    console.error('Error fetching colleges:', error);
    throw error;
  }
};

// Fetch all degrees
export const fetchDegrees = async () => {
  try {
    const response = await axios.get('/api/college/degrees');
    return response.data;
  } catch (error) {
    console.error('Error fetching degrees:', error);
    throw error;
  }
};

// Fetch all branches
export const fetchBranches = async () => {
  try {
    const response = await axios.get('/api/college/branches');
    return response.data;
  } catch (error) {
    console.error('Error fetching branches:', error);
    throw error;
  }
};

// Fetch all degree statuses
export const fetchDegreeStatuses = async () => {
  try {
    const response = await axios.get('/api/college/degree-statuses');
    return response.data;
  } catch (error) {
    console.error('Error fetching degree statuses:', error);
    throw error;
  }
};

// Fetch degree statuses by degree ID
export const fetchDegreeStatusesByDegree = async (degreeId: string) => {
  try {
    const response = await axios.get('/api/degree/degree-statuses', {
      params: { degreeId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching degree statuses by degree ID:', error);
    throw error;
  }
};

// Fetch branches by degree ID
export const fetchBranchesByDegree = async (degreeId: string) => {
  try {
    const response = await axios.get('/api/degree/branches', {
      params: { degreeId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching branches by degree ID:', error);
    throw error;
  }
};

// TypeScript interfaces for user profile details
interface DegreeDetails {
  id: number;
  DegreeName: string;
}

interface CollegeDetails {
  id: number;
  name: string;
  code: string;
  Active_Status: string;
}

interface DegreeStatusDetails {
  DegreeStatusID: number;
  StatusName: string;
  degreeId: number;
}

interface BranchDetails {
  BranchID: number;
  BranchName: string;
  degreeId: number;
}

export interface UserProfile {
  userId: any;
  id: string;
  IIMSTC_ID: string;
  email: string;
  dob: string;
  address: string;
  name: string;
  phoneNumber: string;
  collegeId: number;
  branchId: number;
  semester: string;
  usn: string;
  aadharNo: string;
  gender: string;
  degreeId: number;
  degreeStatusId: number;
  university: string;
  passportPhoto: File | { type: 'Buffer'; data: number[] } | null;
  degreeDetails: DegreeDetails;
  collegeDetails: CollegeDetails;
  degreeStatusDetails: DegreeStatusDetails;
  branchDetails: BranchDetails;
  preScreeningCompleted: boolean;
  aicteFormSubmitted: boolean;
  paymentCompleted: boolean;
  InternshipApproved: boolean;
  interviewScheduled:boolean;
  interviewReject:boolean;
  internshipCompleted: boolean;
  feedbackGiven: boolean;
  Facultyfeedback: boolean;
  Industryfeedback: boolean;


}

// Fetch user profile
export const fetchUserProfile = async () => {
  const token = localStorage.getItem('token');
  const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
  const degreeStatusId = localStorage.getItem('degreeStatusId');

  console.log('Token:', token); // Debug log
  console.log('IIMSTC_ID:', IIMSTC_ID); // Debug log
  console.log('DegreeStatusId:', degreeStatusId); // Debug log

  if (!token || !IIMSTC_ID || !degreeStatusId) {
    console.error('Token, IIMSTC_ID, or degreeStatusId is missing from localStorage');
    throw new Error('Token, IIMSTC_ID, or degreeStatusId is missing from localStorage');
  }

  try {
    const response = await axios.post(`/api/users/profile`, { IIMSTC_ID }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.data && response.data.user) {
      return response.data.user;
    } else {
      console.error('Failed to fetch user profile: Response data is missing user information', response.data);
      throw new Error('Failed to fetch user profile');
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Update user profile
export const updateUserProfile = async (profile: UserProfile) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();

  Object.keys(profile).forEach((key) => {
    const value = profile[key as keyof UserProfile];
    if (value !== undefined) {
      formData.append(key, value as any); // Use 'any' to handle different types
    }
  });

  try {
    const response = await axios.put(`/api/users/update-profile`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

// Fetch user details by user ID
export const fetchUserDetails = async (userId: number) => {
  try {
    const response = await axios.get(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export default {
  completeRegistration,
  loginUser,
  verifyOTP,
  fetchUserProfile,
  updateUserProfile,
  fetchUserDetails,
  fetchColleges,
  fetchDegrees,
  fetchBranches,
  fetchDegreeStatuses,
  fetchDegreeStatusesByDegree,
  fetchBranchesByDegree,
};
